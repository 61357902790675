<template>
  <section>
    <div class="page-header">
      <h3 class="page-title">
        <router-link to="/" class="" style="color: inherit;">
          <i class="mdi mdi-arrow-left mr-3"></i>
        </router-link>
        {{ projeto.nome }}
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <b-card>
          <b-card-title>
            <b-row>
              <b-col md="3">
                <div class="d-flex align-items-center">
                  <h3 class="page-title mb-n2">Arquivos</h3>
                  <p class="mt-2 mb-n1 ml-3 text-muted">
                    {{ totalRows | numberFilter }}
                  </p>
                </div>
              </b-col>
              <b-col
                v-if="$store.getters['auth/hasPermission']('Editar Projetos')"
              >
                <b-button-toolbar
                  aria-label="Barra de Ferramentas"
                  class="d-flex flex-row-reverse"
                >
                  <b-button-group class="mx-1">
                    <b-button variant="success" v-b-toggle.sidebar-edit>
                      <i class="mdi mdi-plus"></i>
                      Novo Arquivo</b-button
                    >
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
          </b-card-title>

          <b-table
            ref="table"
            show-empty
            stacked="md"
            :items="search"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            responsive
            :busy="loadingTable"
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Carregando...</strong>
              </div>
            </template>

            <template #cell(nome_original)="row">
              <div class="media">
                <i
                  class="
                    fa fa-file-excel-o
                    icon-md
                    text-info
                    d-flex
                    align-self-start
                    mr-3
                  "
                ></i>
                <div class="media-body">
                  <h4 class="card-title">
                    <a
                      href=""
                      @click.prevent="downloadArquivo(row.item)"
                      class="text-wrap"
                      >{{ row.item.nome_original }}</a
                    >
                  </h4>
                  <p class="card-text">
                    {{ row.item.created_at | formatDateTime }}
                  </p>
                </div>
              </div>
            </template>
            <template #cell(extensao)="row">
              <b-badge variant="info" class="text-uppercase">{{
                row.item.extensao
              }}</b-badge>
            </template>
            <template #cell(tamanho)="row">
              {{ row.item.tamanho | fileSize }}
            </template>
            <template #cell(actions)="row">
              <b-button
                size="md"
                variant="primary"
                class="btn-icon-text mr-1"
                title="Download"
                @click="downloadArquivo(row.item)"
              >
                <i class="mdi mdi-download btn-icon-prepend"></i> Download
              </b-button>
              <b-button
                v-if="$store.getters['auth/hasPermission']('Editar Projetos')"
                size="md"
                variant="outline-danger"
                class="mr-1"
                title="Remover"
                @click="remove(row.item)"
              >
                <i class="mdi mdi-delete"></i>
              </b-button>
            </template>
          </b-table>

          <b-row class="justify-content-md-center">
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="md"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <b-sidebar
      id="sidebar-edit"
      no-title
      bg-variant="dark"
      text-variant="light"
      width="500px"
      shadow
      right
      backdrop
      backdrop-variant="dark"
    >
      <div class="px-3 py-2">
        <h4 class="card-title">Novo Arquivo</h4>
        <b-form-group description="Tamanho Máximo: 64 MB">
          <b-form-file
            @input="uploadArquivo()"
            v-model="arquivo"
            id="arquivo"
            :state="Boolean(arquivo)"
            accept=""
            placeholder="Selecione ou arraste um arquivo aqui..."
            drop-placeholder="Arraste o arquivo aqui..."
          ></b-form-file>
        </b-form-group>
        <div v-if="importing" class="circle-loader"></div>
      </div>
    </b-sidebar>
  </section>
</template>


<script>
import { required } from "vuelidate/lib/validators";
import ProjetoService from "../../services/projeto.service";

export default {
  name: "ProjetoArquivos",
  props: ["projetoId"],
  data() {
    return {
      fields: [
        { key: "nome_original", label: "Nome", sortable: true },
        { key: "extensao", label: "Formato", sortable: false },
        { key: "tamanho", label: "Tamanho" },
        { key: "actions", label: "Ações" },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
      sortBy: "nome",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      projeto: { nome: null },
      arquivo: null,
      loadingTable: false,
      loading: false,
      importing: false,
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
  methods: {
    fetchData() {
      this.projeto = { nome: null };
      this.loading = true;
      ProjetoService.get(this.projetoId)
        .then((response) => {
          this.projeto = response.data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    search(ctx) {
      let params = {
        page: ctx.currentPage,
        perPage: ctx.perPage,
        orderBy: ctx.sortBy,
        sort: ctx.sortDesc ? "desc" : "asc",
      };

      this.loadingTable = true;

      const promise = ProjetoService.getArquivos(this.projetoId, params);

      return promise
        .then((response) => {
          this.loadingTable = false;
          const items = response.data.data;
          this.totalRows = response.data.total;
          return items || [];
        })
        .catch(() => {
          this.loadingTable = false;
          this.totalRows = 0;
        });
    },
    uploadArquivo() {
      if (this.arquivo == null) return;

      this.importing = true;
      let formData = new FormData();
      formData.append("arquivo", this.arquivo);
      const promise = ProjetoService.uploadArquivo(this.projetoId, formData);

      return promise
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "Sucesso!",
            autoHideDelay: 1000,
            toaster: "b-toaster-bottom-left",
            variant: "success",
          });
          this.importing = false;
          this.arquivo = null;
          this.$refs.table.refresh();
          this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
        })
        .catch(() => {})
        .then(() => {
          this.importing = false;
        });
    },
    remove(item) {
      this.$swal({
        title: "Deseja realmente excluir este registro?",
        text: "Esta ação não poderá ser revertida!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          const promise = ProjetoService.deleteArquivo(this.projetoId, item.id);
          return promise
            .then((response) => {
              this.$bvToast.toast(response.data.message, {
                title: "Sucesso!",
                autoHideDelay: 1000,
                toaster: "b-toaster-bottom-left",
                variant: "success",
              });
              this.$refs.table.refresh();
            })
            .catch(() => {})
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
    downloadArquivo(item) {
      this.loading = true;
      const promise = ProjetoService.downloadArquivo(this.projetoId, item.id);
      return promise
        .then((response) => {
          this.loading = false;
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = item.nome_original;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
  },
  validations: {
    cliente: {
      nome: {
        required,
      },
    },
  },
};
</script>
